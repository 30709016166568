<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page2">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_shadow m_8">
                  <div class="box box_one">
                    <div class="box_list_wrap">
                      <div class="box_list">
                        <div class="list_ttl">
                          <div class="left">
                            <div class="m_4">
                              <span class="build_name">{{ buildingName }}</span>
                            </div>
                            <p class="white_wrap ft_m_16">
                              {{ vote.title }}
                            </p>
                            <div class="price_box">
                              <img
                                src="@/assets/static/images/i_alarm.svg"
                                alt="icon"
                              />
                              <span class="price_txt"
                                >{{
                                  $dayjs(vote.voteStartDate).format(
                                    'YYYY.MM.DD'
                                  )
                                }}
                                ~
                                {{
                                  $dayjs(vote.voteEndDate).format('YYYY.MM.DD')
                                }}</span
                              >
                            </div>
                          </div>
                          <div class="middle_box flex1none">
                            <div class="middle">
                              <p
                                class="price r_12"
                                :class="{
                                  deposit: vote.isVoting === 1,
                                  f_g: vote.isVoting === 2
                                }"
                              >
                                {{
                                  vote.isVoting === 1
                                    ? '투표 진행중'
                                    : '투표 종료'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line_grey none"></div>
                    </div>
                    <div class="text_box">
                      <span>{{ vote.contents }}</span>
                    </div>
                    <div class="box_shadow p_0" v-show="vote.isVoting === 2">
                      <div class="box">
                        <div class="box_ttl size18">
                          <img
                            src="@/assets/static/images/icon_05.svg"
                            alt="icon"
                          />
                          <p>투표 결과</p>
                        </div>
                        <div class="box_con">
                          <div class="box_list_wrap wrap_notice">
                            <div class="vote_box">
                              <div class="vote on">
                                찬성:
                                {{ agree === 0 ? '0' : agree }}% ({{
                                  vote.agree
                                }}표)
                              </div>
                              <div class="vote">
                                반대:
                                {{ disagree === 0 ? '0' : disagree }}% ({{
                                  vote.disagree
                                }}표)
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="isDone === 1 && vote.isVoting === 1">
          <div class="btn_wrap">
            <button class="btn">투표 참여 완료</button>
            <!--버튼 비활성화-->
          </div>
        </div>
        <div class="page_btn" v-if="isDone === 0 && vote.isVoting === 1">
          <div class="btn_wrap">
            <div class="inline">
              <button class="btn left" @click="fnVote(0)">반대하기</button>
              <button class="btn on" @click="fnVote(1)">찬성하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import CONFIRM from '@/components/common/modal/alert/confirm'

export default {
  name: 'menu-vote-view',
  description: '투표상세 페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const query = route.query

    const userData = computed(() => store.getters['user/getData'])

    const state = reactive({
      buildingName: userData.value.buildingName,
      isDone: 0,
      agree: 0,
      disagree: 0,
      vote: {
        title: '',
        contents: '',
        isVoting: '',
        voteStartDate: '',
        voteEndDate: '',
        agree: 0,
        disagree: 0
      }
    })

    const fetchData = async () => {
      const res = await proxy.$VoteSvc.fetchUserVote(query)
      if (res.code === 1) {
        state.vote = res.entity.vote
        state.isDone = res.entity.isDone
        state.agree = res.entity.agree
        state.disagree = res.entity.disagree
      }
    }

    const fnVote = async item => {
      const payload = {}
      payload.component = CONFIRM
      payload.layout = {
        title: '알림',
        contents:
          item === 0 ? '반대로 투표하시겠습니까?' : '찬성으로 투표하시겠습니까?'
      }
      payload.data = {}
      payload.callBackDone = async () => {
        const params = {}
        params.voteNo = Number(query.voteNo)
        params.voteType = item
        const res = await proxy.$VoteSvc.updateUserVote(params)
        if (res.code === 1) {
          alert('투표되었습니다.')
          await proxy.$SignSvc.postMapChangeToken()
          await router.go(-1)
        }
      }
      payload.callBackCancel = proxy => {
        proxy.$emit('close')
      }
      await store.dispatch('layout/pushModalAlertComponent', payload)
    }

    fetchData()
    return { ...toRefs(state), fetchData, fnVote }
  }
}
</script>

<style scoped></style>
